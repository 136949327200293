import React from 'react';
import PropTypes from 'prop-types';

import { graphql, useStaticQuery } from "gatsby"


const ImageGallery = (props) => {


	const data = useStaticQuery(
		graphql`query {
	images: allFile(filter: {sourceInstanceName: {eq: "naming"}}, sort: {order: ASC, fields: relativePath}) {
		edges {
		  node {
			relativePath
			base
		  }
		}
	}
}
`)


	const galleryImages = data.images.edges.filter(edge =>
		edge.node.relativePath.startsWith(props.foldername)
	)

	return (
		<div className='naming-images-wrapper ball-js'>
			<div className='naming-images-clip'>
				{galleryImages.map((image, i) => {
					return (<img key={i} className={`naming-image ${i == 1 ? "naming-image__hover" : ''}`} src={`/naming/${image.node.relativePath}`} loading="lazy"></img>)
				})}
			</div>
		</div >
	)
}


ImageGallery.propTypes = {
	foldername: PropTypes.string,
	bgColor: PropTypes.string
};

export default ImageGallery;