import React, { useRef, useState } from 'react';
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Intro from '../components/naming/intro'
import Icons from '../components/icons'
import PropTypes from 'prop-types';
import Cursor from '../components/cursor'
import { LocomotiveScrollProvider } from 'react-locomotive-scroll'

import arrow from "../images/arrow-right-clients.svg"
import ImageGallery from '../components/naming/image-gallery';

function NamingIndex({ data }) {

	const [modalVisible, setIsShowing] = useState(false);

	function updateStateModal() {
		setIsShowing(!modalVisible);
	}

	const containerRef = useRef(null)

	const posts = [...data.withOrder.edges, ...data.withoutOrder.edges];
	const refs = useRef([]);

	return (
		<>
			<LocomotiveScrollProvider options={{ smooth: true, tablet: { smooth: true }, smartphone: { smooth: false }}} watch={[]} containerRef={containerRef}>
				<main id="top" data-scroll-container ref={containerRef}>
					<Layout type="naming" left="naming" visible={modalVisible} showreelModal={updateStateModal} >
						<Intro />
						<section id="naming" className="content-wrapper">
							{posts.map(({ node }, i) => {
								return (
									<div key={i} ref={refs.current[i]} id={node.folder} className="portfolio-item" data-scroll>
										<div className="max-width-big">
											<div className="row reverse-mobile">
												<div className="col-xs-12 col-md-7 p-relative pl-50">
													<span className="number">#{('000' + (i + 1)).slice(-3)}</span>

													<ImageGallery foldername={node.folder} />
												</div>
												<div className="col-xs-12 col-md-5 description-wrapper p-relative">
													<div className="item-title align-center">
														<span className='copyright'>®</span>
														<p>{node.title}</p>
													</div>
													<div className="item-description align-center">
														<img className="arrow-right-clients" src={arrow} />
														<div dangerouslySetInnerHTML={{
															__html: node.description?.childMarkdownRemark?.html,
														}} />
													</div>
													<div className='row flex-shrink p-relative align-center'>
														<span className="www">
															www
														</span>
														<div className='naming-details'>
															<div className='col-xs-6'>
																<div className="item-description-small">
																	<div dangerouslySetInnerHTML={{
																		__html: node.domnioEncontrado?.childMarkdownRemark?.html,
																	}} />
																</div>
															</div>
															<div className='col-xs-6'>
																<div className="item-description-small">
																	<div dangerouslySetInnerHTML={{
																		__html: node.marcaRegistada?.childMarkdownRemark?.html,
																	}} />
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								)
							})}

							<div className="intro-logo-box right" data-scroll data-scroll-sticky data-scroll-target="#scroll-direction" data-scroll-id="rotateFoxIcon">
								<svg className="fox-icon-circle" viewBox="0 0 116.87 119.28">
									<use xlinkHref="#luis-vaz-3" />
								</svg>
							</div>


						</section>
						<Icons />
					</Layout>

				</main>
			</LocomotiveScrollProvider>
			<Cursor />
		</>

	)
}

NamingIndex.propTypes = {
	data: PropTypes.object.isRequired,
};
export default NamingIndex


export const pageQuery = graphql`
query NamingIndexQuery {
  withOrder: allContentfulNaming(filter: {}, sort: {fields: order, order: DESC}) {
    edges {
      node {
        title
        slug
        folder
				description {
          childMarkdownRemark {
            html
          }
        }
        marcaRegistada {
          childMarkdownRemark {
            html
          }
        }
        domnioEncontrado {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
  withoutOrder: allContentfulNaming(
    filter: {order: {eq: null}}
    sort: {fields: order, order: DESC}
  ) {
    edges {
      node {
        title
        slug
        folder
				description {
          childMarkdownRemark {
            html
          }
        }
        marcaRegistada {
          childMarkdownRemark {
            html
          }
        }
        domnioEncontrado {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
}
		`
