import React from "react";
import arrowDown from "../../images/arrow-down-blue.svg"

const Intro = () => {

	return (
		<section id="intro" className="intro p-relative max-width">
			<div className="row pb-100">
				<div className="col-md-offset-7 col-md-5 col-xs-12 pt-100">
					<div className="row">
						<div className="col-xs-12">
							<div className="logotipos-about">
								<p className="leading-text leading-text--position">
									olá<br /><br />
									este é o meu portfólio<br />
									de<span className="leading-text--effect"> naming...</span><br />
									nã quê? eu explico.<br />
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className="col-xs-12 pt-100">
					<div className="row">
						<div className="col-xs-12">
							<p className="naming-about">
								crio e sugiro <b>nomes originais,</b> que sejam <span className="p-relative">elegíveis<span className="underline"></span></span> para registo de marca e tenham o domínio coincidente ainda disponível.
							</p>
							<div className="naming-about-small-wrapper">
								<p className="naming-about-small">
									também desenho os logotipos
								</p>
								<img className="arrow-down" src={arrowDown} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

	)
};
export default Intro